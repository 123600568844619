// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'

import PDFReader from '@bodhi-project/components/lib/PDFReader'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Summer Camp 2019-20',
  nakedPageSlug: 'summer-camp',
  pageAbstract: 'Summer camp 2019-20 coming soon...',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

const pdfStyles = css({
  maxHeight: '600px !important',
  height: '600px !important',

  '& > div': {
    height: '600px !important',
    maxHeight: '600px !important',

    '& > iframe': {
      height: '600px !important',
      maxHeight: '600px !important',
    },
  },
}).toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = () => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1>Summer Camp</h1>
    <div style={{ background: '#d1d1d1', padding: 12 }}>
      <div style={{ width: '100%', margin: 'auto' }}>
        <PDFReader
          url="https://www.cisfgjal.school/summer-camp-2019-v1.pdf"
          className={pdfStyles}
        />
      </div>
      &nbsp;
    </div>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
